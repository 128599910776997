import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Subscribe - The Smile Stylist" 
    description="Subscribe to Oakville Dentists mailing list" 
    keywords="discount dentist, giveaway, dental services ca, smilestylist, dr kaloti, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content" role="main">
    <div className="page__subscribe">
      <div className="bg__outer">
        <div className="subscribe__content">
          <img src={ require("../images/desktop/subscribe-logo.png" )} alt="" className="register__logo" />

          <h2 className="join-cta">Join our mailing list and receive:</h2>
          <ul className="join-features">
            <li>Monthly cosmetic <b>promotions and discounts</b></li>
            <li>Automatic entrances to win monthly <b>free whitening</b> giveaways</li>
            <li>Automatic entrance for a <b>FREE smile makeover valued at $30,000</b></li>
            <li>Special Rogers Cup ticket pricing & entrance to <b>win front row tickets
              at this years Rogers Cup</b></li>
          </ul>
          <form action="/maily.php" method="post">
            <input type="text" name="txt_name" id="name" placeholder="Name:" required />
            <input type="email" name="txt_email" id="email" placeholder="Email:" required />
            <input type="submit" value="Register" id="submity" />
            <div className="form-group">
              <div className="contact__checkbox">
                <label for="subscribe">
                  <input id="subscribe" type="checkbox" name="subscribe" />
                  <span className="contact-checkbox__label">Sign up to the Smile Stylist newsletter and receive the latest news, specials & updates. </span>
                </label>
              </div>
            </div>
          </form>
          <div className="row social">
          <span className="fb__connect js--fbc">
          <span className="fa fa-facebook"></span>
          <span>Connect with Facebook</span>
          </span>
            <span className="gplus__connect js--gac">
        <span className="fa fa-google-plus"></span>
          <span>Sign up with Google</span>
        </span>
          </div>
        </div>
      </div>
    </div>
  </main>
  </Layout>
)

export default AboutKalotiPage